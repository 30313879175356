
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as formulairejjsGFekBMIMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue?macro=true";
import { default as indexhNp6IBlHLFMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue?macro=true";
import { default as _91k_93FMGrTQUgjeMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue?macro=true";
import { default as acteursy92ztS7AzeMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs.vue?macro=true";
import { default as configurationJRnWEKuQnbMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/configuration.vue?macro=true";
import { default as formulairemga72qoDAjMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue?macro=true";
import { default as indexac6QFlT9ZgMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue?macro=true";
import { default as _91k_93qumvHdT6MoMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue?macro=true";
import { default as territoiresMAle9qrupnMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires.vue?macro=true";
import { default as utilisateurs_45permissionsz48CFoUQqcMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue?macro=true";
import { default as formulairejnZmM9Y2shMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexh7lbLEVnMIMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93WCdSzkSRGbMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue?macro=true";
import { default as utilisateursIwga93SlrPMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue?macro=true";
import { default as indexRFjPADgckSMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_93Ix8OOsZbszMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexkFASGdSupNMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieh2lnqYNV2ZMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as contacts8vWc50IYyMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/contact.vue?macro=true";
import { default as donnees_45personnellesnJ70axsc2MMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93qf92KdlRVLMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/download/[k].vue?macro=true";
import { default as erreursFwOjzeLx4Meta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/erreur.vue?macro=true";
import { default as formulaire7xjn776ySHMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue?macro=true";
import { default as indexjMvYRIOyx6Meta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue?macro=true";
import { default as _91k_93oBCQi061kWMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue?macro=true";
import { default as controles_45simultaneshIvEaundCqMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue?macro=true";
import { default as formulaireCkql3c2tIrMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue?macro=true";
import { default as indexi7UrLQqNkxMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue?macro=true";
import { default as _91k_93BqP4zdTvETMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue?macro=true";
import { default as envoi_45de_45mailbISnIPEZExMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue?macro=true";
import { default as historiqueeGYI7FHBulMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/historique.vue?macro=true";
import { default as formulaireXJSSdTuCDdMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue?macro=true";
import { default as indexwg9gFAWuClMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue?macro=true";
import { default as _91k_93PrD3hOyEC4Meta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import/[k].vue?macro=true";
import { default as importqlGwjZQBQ5Meta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import.vue?macro=true";
import { default as formulaireHLZzm0z2urMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue?macro=true";
import { default as indexE1BJgzvcWEMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue?macro=true";
import { default as _91k_93YnC3aMO5ZUMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue?macro=true";
import { default as indisponibilites_45programmeesupNUjOW3KyMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue?macro=true";
import { default as formulaireYF95B5GL0UMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue?macro=true";
import { default as indexsU0Rqf2vFHMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue?macro=true";
import { default as _91k_93Us7WAcUKkbMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue?macro=true";
import { default as pei3wyjZX3VJCMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei.vue?macro=true";
import { default as formulairei4rpXljU7mMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue?macro=true";
import { default as indexyf7mtKYKZPMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue?macro=true";
import { default as _91k_93fZH6MKrztmMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue?macro=true";
import { default as tournees6H2cFrxLs7Meta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees.vue?macro=true";
import { default as indexYWM2zQLC8oMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/index.vue?macro=true";
import { default as indexEg9lWhSB2QMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/profil/index.vue?macro=true";
import { default as profilHmjmQbPRnZMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/profil.vue?macro=true";
import { default as ressources4bmkWRa6yLMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/ressources.vue?macro=true";
import { default as tableau_45de_45bordN6EEZ2LeOUMeta } from "/builds/9680856789/hydraclic-v2/nuxt/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: "administration-acteurs",
    path: "/administration/acteurs",
    meta: acteursy92ztS7AzeMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs.vue"),
    children: [
  {
    name: _91k_93FMGrTQUgjeMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue"),
    children: [
  {
    name: "administration-acteurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue")
  },
  {
    name: "administration-acteurs-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-configuration",
    path: "/administration/configuration",
    meta: configurationJRnWEKuQnbMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/configuration.vue")
  },
  {
    name: "administration-territoires",
    path: "/administration/territoires",
    meta: territoiresMAle9qrupnMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires.vue"),
    children: [
  {
    name: _91k_93qumvHdT6MoMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue"),
    children: [
  {
    name: "administration-territoires-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue")
  },
  {
    name: "administration-territoires-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-utilisateurs-permissions",
    path: "/administration/utilisateurs-permissions",
    meta: utilisateurs_45permissionsz48CFoUQqcMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue")
  },
  {
    name: "administration-utilisateurs",
    path: "/administration/utilisateurs",
    meta: utilisateursIwga93SlrPMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue"),
    children: [
  {
    name: _91k_93WCdSzkSRGbMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue"),
    children: [
  {
    name: "administration-utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "administration-utilisateurs-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexRFjPADgckSMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oublieh2lnqYNV2ZMeta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieh2lnqYNV2ZMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93Ix8OOsZbszMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: indexkFASGdSupNMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/contact.vue")
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/donnees-personnelles.vue")
  },
  {
    name: "download-k",
    path: "/download/:k()",
    meta: _91k_93qf92KdlRVLMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/download/[k].vue")
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreursFwOjzeLx4Meta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/erreur.vue")
  },
  {
    name: "gestion-controles-simultanes",
    path: "/gestion/controles-simultanes",
    meta: controles_45simultaneshIvEaundCqMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue"),
    children: [
  {
    name: _91k_93oBCQi061kWMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue"),
    children: [
  {
    name: "gestion-controles-simultanes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue")
  },
  {
    name: "gestion-controles-simultanes-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-envoi-de-mail",
    path: "/gestion/envoi-de-mail",
    meta: envoi_45de_45mailbISnIPEZExMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue"),
    children: [
  {
    name: _91k_93BqP4zdTvETMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue"),
    children: [
  {
    name: "gestion-envoi-de-mail-k-formulaire",
    path: "formulaire",
    meta: formulaireCkql3c2tIrMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue")
  },
  {
    name: "gestion-envoi-de-mail-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-historique",
    path: "/gestion/historique",
    meta: historiqueeGYI7FHBulMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/historique.vue")
  },
  {
    name: "gestion-import",
    path: "/gestion/import",
    meta: importqlGwjZQBQ5Meta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import.vue"),
    children: [
  {
    name: _91k_93PrD3hOyEC4Meta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import/[k].vue"),
    children: [
  {
    name: "gestion-import-k-formulaire",
    path: "formulaire",
    meta: formulaireXJSSdTuCDdMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue")
  },
  {
    name: "gestion-import-k",
    path: "",
    meta: indexwg9gFAWuClMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-indisponibilites-programmees",
    path: "/gestion/indisponibilites-programmees",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue"),
    children: [
  {
    name: _91k_93YnC3aMO5ZUMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue"),
    children: [
  {
    name: "gestion-indisponibilites-programmees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue")
  },
  {
    name: "gestion-indisponibilites-programmees-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-pei",
    path: "/gestion/pei",
    meta: pei3wyjZX3VJCMeta || {},
    alias: ["/gestion/carte"],
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei.vue"),
    children: [
  {
    name: _91k_93Us7WAcUKkbMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue"),
    children: [
  {
    name: "gestion-pei-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue")
  },
  {
    name: "gestion-pei-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-tournees",
    path: "/gestion/tournees",
    meta: tournees6H2cFrxLs7Meta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees.vue"),
    children: [
  {
    name: _91k_93fZH6MKrztmMeta?.name,
    path: ":k()",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue"),
    children: [
  {
    name: "gestion-tournees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue")
  },
  {
    name: "gestion-tournees-k",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/index.vue")
  },
  {
    name: profilHmjmQbPRnZMeta?.name,
    path: "/profil",
    meta: profilHmjmQbPRnZMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/profil/index.vue")
  }
]
  },
  {
    name: "ressources",
    path: "/ressources",
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/ressources.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bordN6EEZ2LeOUMeta || {},
    component: () => import("/builds/9680856789/hydraclic-v2/nuxt/pages/tableau-de-bord.vue")
  }
]